<template>
  <div class="MainPage-wrap">
    <!-- 圓圈圈背景 -->
    <div class="circlesBg"></div>

    <transition name="fade">
      <div v-show="leftItem">
        <!-- 左邊香氛(線) -->
        <div class="leftLine"></div>
        <!-- 左邊香氛(圈圈) -->
        <div class="leftCircle">
          <CircleWave :mainText="'等待時機'" :subText="'...無助無力覺得自己犯錯...'" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show="centerItem">
        <!-- 中間香氛(線) -->
        <div class="centerLine"></div>
        <!-- 中間香氛(圈圈) -->
        <div class="centerCircle">
          <CircleWave :mainText="'突破屏障'" :subText="'...我勇敢去做出改變...'" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show="rightItem">
        <!-- 右邊香氛(線) -->
        <div class="rightLine"></div>
        <!-- 右邊香氛(圈圈) -->
        <div class="rightCircle">
          <CircleWave :mainText="'調頻升維'" :subText="'...穩定好覺得自己想要的...'" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show="bottomItem">
        <!-- 香氛瓶 -->
        <div class="bg-fragrance_bottle1"></div>
        <div class="BottomWave-component">
          <!-- <ItemWave class="ItemWave-component" /> -->
          <MainPageBottom />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CircleWave from '../../components/MainPage/CircleWave';
import MainPageBottom from '../../components/MainPage/MainPageBottom';
// import ItemWave from '../../components/MainPage/ItemWave1';
export default {
  name: 'MainPage',
  components: {
    CircleWave,
    MainPageBottom
    // ItemWave
  },
  data() {
    return {
      leftItem: false,
      centerItem: false,
      rightItem: false,
      bottomItem: false
    };
  },
  computed: {},
  created() {},
  mounted() {
    const self = this;
    setTimeout(() => {
      self.bottomItem = true;
    }, 300);
    setTimeout(() => {
      self.leftItem = true;
    }, 800);
    setTimeout(() => {
      self.rightItem = true;
    }, 1400);
    setTimeout(() => {
      self.centerItem = true;
    }, 2000);
  },
  watch: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.MainPage-wrap {
  // background: linear-gradient(180deg, #3c2657 0%, #30234e 33.85%, #202042 69.27%, #131d39 100%);
  background-image: url('../../assets/img/bg-chart.png');
  // height: 100%;
  // min-height: 100vh;
  // width: 100vw;
  height: 100vh;
  max-width: 414px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.circlesBg {
  background-image: url('../../assets/img/bg-circles.png');
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  animation: blinking 2s infinite;
}
@keyframes blinking {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.leftLine {
  width: 0px;
  height: 230.83px;
  background-color: $Primary;
  border: 2.5px solid $Primary;
  transform: rotate(-21.34deg);
  position: absolute;
  left: 38.4%;
  bottom: 118px;
  transition: 1s all;
}
.leftCircle {
  position: absolute;
  bottom: 37%;
  z-index: 10;
  left: -22px;
}

.centerLine {
  position: absolute;
  width: 0px;
  height: 462.18px;
  left: 49%;
  bottom: 13%;
  background-color: $Primary;
  border: 2.5px solid $Primary;
  transform: rotate(-1.61deg);
}
.centerCircle {
  position: absolute;
  bottom: 65%;
  z-index: 10;
  left: 47%;
  transform: translateX(-50%);
}
.rightLine {
  position: absolute;
  width: 0px;
  height: 297.24px;
  right: 35%;
  bottom: 124px;
  transform: rotate(20.95deg);
  background-color: $Primary;
  border: 2.5px solid $Primary;
}
.rightCircle {
  position: absolute;
  bottom: 45%;
  z-index: 10;
  right: -38px;
}
.bg-fragrance_bottle1 {
  width: 102px;
  height: 141px;
  background: url('../../assets/img/css_sprites_MainPage.png') -10px -10px;
  position: absolute;
  bottom: 27.91px;
  left: 50%;
  transform: translateX(-50%);
}
.BottomWave-component {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ItemWave-component {
  position: absolute;
  bottom: 112px;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style></style>
