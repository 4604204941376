<template>
  <div class="MainPageBottom-wrap" v-touch-pan.mouse="handlePan">
    <!-- 播放鍵 -->
    <div v-if="!mediaPlaying" class="playIcon bg-btn_play" @click="changeMediaPlayingStatus"></div>
    <div v-else class="playIcon bg-btn_playing"></div>
    <ItemWave1 class="ItemWave1-component" :r="250" :g="250" :b="250" :opacity="1" :multipleWave="true" />
    <!-- 標題+內容 -->
    <div class="content">
      <span class="myTitle">困境</span>
      <span class="smallwords">...我覺得現在的生活好累...</span>
    </div>
    <!-- 上滑的提示按鈕 -->
    <div class="slideButton row items-center justify-center" @click="toReportPage">
      <span class="btnText">上滑看解析</span>
      <span class="bg-arrow_up_icon"></span>
    </div>
  </div>
</template>

<script>
import router from '../../router/index';
import ItemWave1 from './ItemWave1';
// import { debounce } from 'quasar';
export default {
  name: 'MainPageBottom',
  props: [],
  components: { ItemWave1 },
  data() {
    return {
      // 音檔是否正在撥放
      mediaPlaying: false
    };
  },
  created() {},
  computed: {},
  methods: {
    // 偵測使用者向哪裡滑的偵測器
    handlePan({ evt, ...info }) {
      if (process.env.NODE_ENV === 'development') console.log(info);
      // native Javascript event
      if (process.env.NODE_ENV === 'development') console.log(evt);
      if (info.direction === 'up') {
        setTimeout(() => {
          this.toReportPage();
        }, 300);
      }
    },
    // 偵測使用者向哪裡滑的偵測器
    // handlePan: debounce(function({ evt, ...info }) {
    //   // if (process.env.NODE_ENV === 'development') console.log(evt.srcElement.offsetTop);
    //   if (process.env.NODE_ENV === 'development') console.log(info);
    //   if (info.direction === 'up') {
    //     setTimeout(() => {
    //       this.toReportPage();
    //     }, 300);
    //   }
    // }, 100),
    // 按下播放鍵，改變 icon 圖示，並播放音檔
    changeMediaPlayingStatus() {
      // 改變 mediaPlaying 狀態
      this.mediaPlaying = !this.mediaPlaying;
      const self = this;
      // TODO: 之後要改成播放音檔，播完後，更改回狀態
      setTimeout(() => {
        // 改變 mediaPlaying 狀態
        self.mediaPlaying = !self.mediaPlaying;
      }, 2000);
    },
    // 至報告頁面
    toReportPage() {
      router.push('/ReportPage');
    }
  }
};
</script>
<style scoped lang="scss">
.MainPageBottom-wrap {
  width: 100%;
  height: 113px;
  background: #ffffff;
  opacity: 0.9;
}
.bg-arrow_up_icon {
  width: 10px;
  height: 12px;
  background: url('../../assets/img/css_sprites_MainPage.png') -232px -110px;
  animation: upAndDown 0.8s infinite;
}
.ItemWave1-component {
  width: 100%;
  bottom: 113px;
}
.content {
  padding-bottom: 20px;
  margin: 67px auto 0 auto;
  text-align: center;
  .myTitle {
    font-weight: 500;
    font-size: 20px;
    color: #3b2657;
    margin-right: 16px;
  }
  .smallwords {
    color: #121d38;
  }
}
.slideButton {
  width: 200px;
  height: 40px;
  background: $ButtonPurple;
  border-radius: 50px;
  margin: 0 auto;
  line-height: 40px;
  // margin-bottom: 23px;
  .btnText {
    color: #ffffff;
    margin-right: 5.2px;
  }
}
.playIcon {
  position: absolute;
  top: 1%;
  right: 9%;
  z-index: 100;
}
.bg-btn_play {
  width: 80px;
  height: 80px;
  background: url('../../assets/img/css_sprites_MainPage.png') -132px -10px;
}
.bg-btn_playing {
  width: 80px;
  height: 80px;
  background: url('../../assets/img/css_sprites_MainPage.png') -232px -10px;
}
</style>
<style lang="scss"></style>
