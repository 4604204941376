<template>
  <div class="CircleWave-wrap" @click="changeMediaPlayingStatus">
    <div class="wave wave5"></div>
    <!-- <div class="wave wave4"></div> -->
    <!-- <div class="wave wave3"></div> -->
    <div class="wave wave2"></div>
    <!-- <div class="wave wave1"></div> -->
    <div class="wave wave0"></div>
    <div class="circleContent row items-center justify-center">
      <!-- TODO: 播放要做動畫 -->
      <div v-if="!mediaPlaying" class="playIcon bg-btn_play"></div>
      <div v-else class="playIcon bg-btn_playing"></div>
      <span class="mainText">{{ mainText }}</span>
      <span class="subText">{{ subText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircleWave',
  props: ['data', 'mainText', 'subText'],
  components: {},
  data() {
    return {
      // 音檔是否正在撥放
      mediaPlaying: false
    };
  },
  created() {},
  computed: {},
  methods: {
    // 按下播放鍵，改變 icon 圖示，並播放音檔
    changeMediaPlayingStatus() {
      // 改變 mediaPlaying 狀態
      this.mediaPlaying = !this.mediaPlaying;
      const self = this;
      // TODO: 之後要改成播放音檔，播完後，更改回狀態
      setTimeout(() => {
        // 改變 mediaPlaying 狀態
        self.mediaPlaying = !self.mediaPlaying;
      }, 2000);
    }
  }
};
</script>
<style scoped lang="scss">
.CircleWave-wrap {
  position: relative;
  height: 200px;
  width: 200px;
}
// 圓圈水波 css start
.wave {
  position: absolute;
  top: calc((100% - 140px) / 2);
  left: calc((100% - 140px) / 2);
  width: 140px;
  height: 140px;
  border-radius: 140px;
}
.wave0 {
  background: $DataPurple;
  opacity: 0.3;
  z-index: 2;
  background-size: auto 106%;
  animation: w 2s forwards infinite;
}
.wave1 {
  background: $DataPurple;
  opacity: 0.3;
  z-index: 3;
  animation: w 2s 0.4s forwards infinite;
}
.wave2 {
  background: $DataPurple;
  opacity: 0.3;
  z-index: 4;
  animation: w 2s 0.8s forwards infinite;
}
.wave3 {
  background: $DataPurple;
  opacity: 0.3;
  z-index: 5;
  animation: w 2s 1s forwards infinite;
}
.wave4 {
  background: $DataPurple;
  opacity: 0.3;
  z-index: 6;
  animation: w 2s 1.6s forwards infinite;
}
.wave5 {
  background: #57389a;
  opacity: 0.3;
  z-index: 7;
  animation: w 2s 2s forwards infinite;
}
@keyframes w {
  0% {
    top: calc((100% - 140px) / 2);
    left: calc((100% - 140px) / 2);
    width: 140px;
    height: 140px;
  }
  100% {
    top: calc((100% - 180px) / 2);
    left: calc((100% - 180px) / 2);
    width: 180px;
    height: 180px;
  }
}
// 圓圈水波 css end
.circleContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 140px;
  height: 140px;
  border-radius: 140px;
  border: 2px solid #c0a279;
  background: rgba(102, 90, 177, 0.5);
  padding: 23px 10px;
  text-align: center;
  .playIcon {
    position: absolute;
    top: -38px;
    left: 48%;
  }
  .mainText {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #f2cd9b;
  }
  .subText {
    line-height: 21px;
    color: #ffffff;
  }
}
.bg-btn_play {
  width: 80px;
  height: 80px;
  background: url('../../assets/img/css_sprites_MainPage.png') -132px -10px;
}
.bg-btn_playing {
  width: 80px;
  height: 80px;
  background: url('../../assets/img/css_sprites_MainPage.png') -232px -10px;
}
</style>
<style lang="scss"></style>
